const host = 'case.theingdesign.com'

export default {
  defaultLang: 'ch',
  productionHost: host,
  isProduction: location.host.indexOf(host) > -1 ? true : false,
  deviceWidth: 500,
  waterfallWidth: 550,
  waterfallHeight: 300,
  waterfallLineGap: 0,
  loadingDataTimeout: 500,
  apiHost: location.host.indexOf(host) > -1 ? 'https://api.theingdesign.com' : 'http://localhost:8200',
  workCardScrollBottomHeight: 100,
  workCardPageSize: 9,
  webTitle: 'Case by ING Design',
  webKeywords: 'ING Design, 応設計, 外包',
  webDescription: 'Case 外包專用網站',
}