<template>
  <div
    class="title margin-bottom"
    :class="[center ? 'text-center' : '', bold ? 'text-bold' : '']"
  >
    {{ text }}
    <div class="title-line"></div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  props: {
    text: {
      require: true,
    },
    center: {
      default: false,
    },
    bold: {
      default: true,
    },
  },
};
</script>

<style lang="less" scoped>
.title-line {
  background: #fda78a;
  height: 2px;
  width: 1.25rem;
  margin: 12px 0;
}
</style>