<template>
  <transition name="fade" mode="out-in">
    <div v-if="headerDeviceMenuShow">
      <div class="device-menu-layout">
        <div
          @click="headerDeviceMenuShow = false"
          class="relative padding-sm text-sxl device-menu-close"
        >
          <span class="material-icons right-middle" style="right: 20px">
            close
          </span>
        </div>
        <div class="padding-sm">
          <router-link
            v-for="(item, index) in navList"
            :key="index"
            class="device-menu decoration-none"
            class-active="active"
            :to="item.to"
            exact
          >
            <div @click="headerDeviceMenuShow = false">
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  computed: {
    navList() {
      return this.$store.state.storeCommon.navList;
    },
    headerDeviceMenuShow: {
      get() {
        return this.$store.state.storeCommon.headerDeviceMenuShow;
      },
      set(val) {
        this.HandleSetState(
          "storeCommon/SET_STATE",
          "headerDeviceMenuShow",
          val
        );
      },
    },
  },
};
</script>

<style lang="less" scoped>
.device-menu {
  display: block;
  padding: 20px;
  color: #ccc;
}
.device-menu.active {
  display: block;
  padding: 20px;
  color: #fff;
  font-weight: 500;
}
.device-menu-layout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
}
.device-menu-close {
  background: #000;
  min-height: 85px;
  max-height: 85px;
}
.nav-button {
  padding: 15px 20px;
  color: #999;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
}
.nav-button.active {
  color: #fff;
}
.logo {
  width: 50px;
  margin: 0 20px 0 0;
}
</style>