import configCommon from '@/utils/configCommon'

export default {
  computed: {
    deviceShow() {
      return 'device-show'
    },
    desktopShow() {
      return 'desktop-show'
    },
    fadeIn() {
      return 'animate__animated animate__fadeIn'
    },
    deviceMode() {
      return window.innerWidth < configCommon.deviceWidth ? true : false
    },
    windowWidth() {
      return window.innerWidth
    },
  },
}