<template>
  <div :class="deviceShow" class="padding-sm">
    <div class="text-bold-800 footer-menu-title margin-top">聯絡窗口</div>
    <div class="text-sm">
      小正 Banana<br />
      <a href="mailto:stf74116@gmail.com" class="text-blank"
        >stf74116@gmail.com</a
      >
    </div>
    <div class="text-bold-800 text-xs margin-top">
      保留所有變更權利 © 2021 case.theingdesign.com<br />
      未經同意請勿任意轉載任一圖片或內容
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
};
</script>