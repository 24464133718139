const itemRoute = '/ios-work'

export default {
  namespaced: true,
  state: {
    iosItems: [
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '點數兌換',
      //   src: [
      //     require('@/assets/web/03.jpg'),
      //     require('@/assets/web/ec-1.jpg'),
      //     require('@/assets/web/ec-2.jpg'),
      //     require('@/assets/web/ec-3.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '社交電話',
      //   src: [
      //     require('@/assets/web/02.jpg'),
      //     require('@/assets/web/ec-blog-1.jpg'),
      //     require('@/assets/web/ec-blog-2.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '送餐系統',
      //   src: [
      //     require('@/assets/web/food-1.png'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '兒童智能手錶',
      //   src: [
      //     require('@/assets/web/food-2.png'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '車用卡拉OK',
      //   src: [
      //     require('@/assets/web/airbnb-1.jpg'),
      //     require('@/assets/web/airbnb-2.png'),
      //     require('@/assets/web/airbnb-3.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '富邦放個鹿',
      //   src: [
      //     require('@/assets/web/01.jpg'),
      //     require('@/assets/web/b-backstage-1.jpg'),
      //     require('@/assets/web/b-backstage-2.jpg'),
      //     require('@/assets/web/b-backstage-3.jpg'),
      //     require('@/assets/web/b-backstage-4.jpg'),
      //     require('@/assets/web/b-backstage-5.jpg'),
      //     require('@/assets/web/b-backstage-6.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '解憂福利社',
      //   src: [
      //     require('@/assets/web/04.jpg'),
      //     require('@/assets/web/c-backstage-1.jpg'),
      //     require('@/assets/web/c-backstage-2.jpg'),
      //     require('@/assets/web/c-backstage-3.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '線上夾娃娃機',
      //   src: [
      //     require('@/assets/web/aa-1.jpeg'),
      //     require('@/assets/web/aa-2.jpeg'),
      //     require('@/assets/web/aa-3.jpeg'),
      //     require('@/assets/web/aa-4.jpeg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '運動計步',
      //   src: [
      //     require('@/assets/web/aa-1.jpeg'),
      //     require('@/assets/web/aa-2.jpeg'),
      //     require('@/assets/web/aa-3.jpeg'),
      //     require('@/assets/web/aa-4.jpeg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '',
      //   title: '殺價王',
      //   src: [
      //     require('@/assets/web/aa-1.jpeg'),
      //     require('@/assets/web/aa-2.jpeg'),
      //     require('@/assets/web/aa-3.jpeg'),
      //     require('@/assets/web/aa-4.jpeg'),
      //   ],
      //   detailShow: false,
      // },
    ],
  },
  mutations: {
    SET_STATE: (state, data) => {
      state[data.key] = data.val
    },
  },
}