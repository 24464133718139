import Vue from 'vue'
import Vuex from 'vuex'

import storeCommon from './modules/storeCommon'
import mgWork from './modules/mgWork'
import webWork from './modules/webWork'
import iosWork from './modules/iosWork'
import concertWork from './modules/concertWork'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    storeCommon,
    mgWork,
    webWork,
    iosWork,
    concertWork,
  },
})

export default store
