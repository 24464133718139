const storeCommon = {
  namespaced: true,
  state: {
    headerDeviceMenuShow: false,
    navList: [
      {
        name: '演唱會作品',
        to: '/concert-work'
      },
      {
        name: 'MG 動畫作品',
        to: '/mg-work'
      },
      {
        name: '聯絡我們',
        to: '/contact'
      },
    ],
  },
  mutations: {
    SET_STATE: (state, data) => {
      state[data.key] = data.val
    },
  },
}

export default storeCommon