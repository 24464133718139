<template>
  <div class="navbar-container">
    <el-row :class="fadeIn">
      <el-col :span="12">
        <div class="relative" style="height: 50px">
          <router-link to="/">
            <img class="left-middle logo" src="@/assets/logo.png" />
          </router-link>
        </div>
      </el-col>
      <el-col :span="12">
        <div :class="desktopShow" class="text-right" style="height: 50px">
          <router-link
            v-for="(item, index) in navList"
            :key="index"
            class="nav-button ease-in-out-03s text-sm inline-block"
            class-active="active"
            :to="item.to"
            exact
          >
            {{ item.name }}
          </router-link>
        </div>
        <div :class="deviceShow">
          <span
            class="material-icons right-middle text-sxl"
            @click="headerDeviceMenuShow = true"
          >
            menu
          </span>
        </div>
      </el-col>
    </el-row>

    <HeaderDevice />
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import HeaderDevice from "@/components/HeaderDevice";

export default {
  mixins: [mainCommon],
  components: {
    HeaderDevice,
  },
  computed: {
    navList() {
      return this.$store.state.storeCommon.navList;
    },
    headerDeviceMenuShow: {
      get() {
        return this.$store.state.storeCommon.headerDeviceMenuShow;
      },
      set(val) {
        this.HandleSetState(
          "storeCommon/SET_STATE",
          "headerDeviceMenuShow",
          val
        );
      },
    },
  },
};
</script>

<style lang="less" scoped>
.device-menu {
  display: block;
  padding: 20px;
  color: #ccc;
}
.device-menu.active {
  display: block;
  padding: 20px;
  color: #fff;
  font-weight: 500;
}
.device-menu-layout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
}
.device-menu-close {
  background: #000;
  min-height: 85px;
  max-height: 85px;
}
.nav-button {
  padding: 15px 20px;
  color: #666;
  text-decoration: none;
  &:hover {
    color: #ccc;
  }
}
.nav-button.active {
  color: #ccc;
}
.logo {
  height: 25px;
  margin: 0 20px 0 0;
}
@media screen and (max-width: 659px) {
  .logo {
    height: 20px;
  }
}
</style>