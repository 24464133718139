<template>
  <div class="footer-container">
    <div :class="fadeIn" class="footer">
      <div :class="desktopShow">
        <el-row class="footer-menu-container">
          <el-col :span="5">
            <div class="text-bold-800 footer-menu-title">聯絡窗口</div>
            <div class="text-sm">
              小正 Banana<br />
              <a href="mailto:stf74116@gmail.com" class="text-blank"
                >stf74116@gmail.com</a
              >
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="text-right">
            <span class="text-bold-800 text-xs">
              保留所有變更權利 © 2021 case.theingdesign.com<br />
              未經同意請勿任意轉載任一圖片或內容
            </span>
          </el-col>
        </el-row>
      </div>

      <FooterDevice />
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import FooterDevice from "@/components/FooterDevice";

export default {
  mixins: [mainCommon],
  components: {
    FooterDevice,
  },
};
</script>

<style lang="less" scoped>
</style>
