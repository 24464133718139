const itemRoute = '/mg-work'

export default {
  namespaced: true,
  state: {
    mgItems: [
      {
        route: itemRoute,
        description: ``,
        title: '航太產業聚落仁武產業園區 / 示意動畫',
        src: [
          require('@/assets/mg/mg-14.mp4'),
          require('@/assets/mg/mg-15.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '臺中軟體園區形象短片 / 示意動畫',
        src: [
          require('@/assets/mg/mg-11.mp4'),
          require('@/assets/mg/mg-12.mp4'),
          require('@/assets/mg/mg-13.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '節慶祝賀動畫 / 聖誕節',
        src: [
          require('@/assets/mg/mg-1.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '節慶祝賀動畫 / 新年',
        src: [
          require('@/assets/mg/mg-2.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '節慶祝賀動畫 / 情人節',
        src: [
          require('@/assets/mg/mg-3.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '節慶祝賀動畫 / 兒童節',
        src: [
          require('@/assets/mg/mg-4.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '節慶祝賀動畫 / 元宵節',
        src: [
          require('@/assets/mg/mg-8.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '節慶祝賀動畫 / 端午節',
        src: [
          require('@/assets/mg/mg-9.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '節慶祝賀動畫 / 母親節',
        src: [
          require('@/assets/mg/mg-10.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '泰山氣泡水跨年慶賀廣告',
        src: [
          require('@/assets/mg/mg-5.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: '嘉義縣吉祥物片頭',
        src: [
          require('@/assets/mg/mg-6.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: ``,
        title: 'CARPOST 行銷動畫',
        src: [
          require('@/assets/mg/mg-7.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          全明星運動會主視覺動畫<br><br>
          動畫設計：小綠
        `,
        title: '全明星運動會主視覺動畫',
        src: [
          require('@/assets/mg/sport-4.jpg'),
          require('@/assets/mg/sport-2.mp4'),
          // require('@/assets/mg/sport-3.mp4'),
          // require('@/assets/mg/sport-1.mp4'),
          // require('@/assets/mg/sport-5.jpg'),
          require('@/assets/mg/sport-6.jpg'),
        ],
        detailShow: false,
      },
    ],
  },
  mutations: {
    SET_STATE: (state, data) => {
      state[data.key] = data.val
    },
  },
}