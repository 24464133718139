import Cookies from 'js-cookie'
import { slackHandler } from '@/utils/slack'
import configCommon from '@/utils/configCommon'
import DeviceDetector from 'device-detector-js'

const deviceDetector = new DeviceDetector()
const device = deviceDetector.parse(navigator.userAgent)

export default {
  methods: {
    HandleRouteOpen(to) {
      this.HandleRouteHistory(to, 'opened')
      window.open(to)
    },
    HandleRouteHistory(to, action, previewTitle = '') {
      let fields = [
        {
          title: 'Browser',
          value: `${device.client.name} (Version: ${device.client.version})`,
          short: true
        },
        {
          title: 'Device',
          value: `${device.device.brand || 'Unknown'} (Type: ${device.device.type || 'Unknown'})`,
          short: true
        },
        {
          title: 'OS',
          value: `${device.os.name} (Version: ${device.os.version || 'Unknown'})`,
          short: true
        },
        {
          title: 'IP',
          value: Cookies.get('ip'),
          short: true
        },
        {
          title: 'Route',
          value: location.href,
          short: false
        },
      ]
      if (action === 'preview')
        fields.push({
          title: 'Preview Title',
          value: previewTitle,
          short: false
        })
      slackHandler(`${Cookies.get("ip")} ${action} ${to}.`, configCommon.isProduction ? 'studio-route-history-prod' : 'studio-route-history-dev', [{
        color: '#2ec9d3', // 2ec9d3 | f77d7d | 36a64f
        fields: fields
      }])
    },
    HandleRouteState(u) {
      window.history.pushState('', '', u)
    },
    HandleWindowScrollListener(isListen, func) {
      if (isListen)
        return window.addEventListener('scroll', func)
      return window.removeEventListener('scroll', func)
    },
    HandleShuffle(i) {
      i.sort(() => {
        return Math.random() - 0.5
      })
    },
    HandleSetState(s, k, v) {
      this.$store.commit(s, { key: k, val: v })
    },
    HandleGetCookie(k) {
      return Cookies.get(k)
    },
    HandleSetCookie(k, v) {
      return Cookies.set(k, v)
    },
    HandleRemoveCookie(k) {
      return Cookies.remove(k)
    },
    CapitalizeFirstLetter(s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
  },
}