const itemRoute = '/concert-work'

export default {
  namespaced: true,
  state: {
    concertItems: [
      {
        route: itemRoute,
        description: `
        第17 屆KKBOX 音樂風雲榜｜ 17th KKBOX Music Awards<br>
        韋禮安 請妳嫁給我<br>
        .<br>
        視覺統籌_ 傑森哥<br>
        現場視覺執行_傑森哥 海綿 狗狗 阿浮<br>
        專案經理_ 秀秀mina<br>
        燈光_ 柏廷<br>
        舞台視訊動畫設計_ 小綠 @greengetup`,
        title: '韋禮安 - 請妳嫁給我 / 2022 第 17 屆 KKBOX 音樂風雲榜',
        src: [
          require('@/assets/mg/weibirdmusic-kkbox-3.jpg'),
          require('@/assets/mg/weibirdmusic-kkbox-2.mp4'),
          require('@/assets/mg/weibirdmusic-kkbox-1.mp4'),
          require('@/assets/mg/weibirdmusic-kkbox-4.jpg'),
          require('@/assets/mg/weibirdmusic-kkbox-5.jpg'),
          require('@/assets/mg/weibirdmusic-kkbox-6.jpg'),
          require('@/assets/mg/weibirdmusic-kkbox-7.jpg'),
          require('@/assets/mg/weibirdmusic-kkbox-8.jpg'),
          require('@/assets/mg/weibirdmusic-kkbox-9.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
        第17 屆KKBOX 音樂風雲榜｜ 17th KKBOX Music Awards<br>
        邱鋒澤 呂爵安 年青有為<br>
        .<br>
        視覺統籌_ @jasonling330<br>
        現場視覺執行_ @jasonling330 @haimiaan.art 狗狗 @raefuse_<br>
        燈光_ @b.b_lin1997<br>
        專案經理_ @minahsiao24<br>
        舞台視訊動畫設計_ 小綠 @greengetup<br>
        ·<br>
        感謝傑森哥在視覺設計上的建議，讓這首歌在現場執行的效果好棒 (´∀｀)<br>
        好珍惜這段時間和同事們打打鬧鬧，講幹話的日子，溫馨又窩心，希望以後還能繼續QAQ<br>
        感謝工具人兼長期飯票 @ianakaberlin<br>
        每天接送社畜如我下班，有你真好<3`,
        title: '邱鋒澤x呂爵安 - 年青有為 / 2022 第 17 屆 KKBOX 音樂風雲榜',
        src: [
          require('@/assets/mg/qiufengze-kkbox-2.jpg'),
          require('@/assets/mg/qiufengze-kkbox-1.mp4'),
          require('@/assets/mg/qiufengze-kkbox-3.mp4'),
          require('@/assets/mg/qiufengze-kkbox-4.mp4'),
          require('@/assets/mg/qiufengze-kkbox-5.jpg'),
          require('@/assets/mg/qiufengze-kkbox-6.jpg'),
          require('@/assets/mg/qiufengze-kkbox-7.jpg'),
          require('@/assets/mg/qiufengze-kkbox-8.jpg'),
          require('@/assets/mg/qiufengze-kkbox-9.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
        一邊摸索新軟體一邊輸出真的好挑戰!!<br><br>
        覺得boss心很大，願意讓我嘗試用新東西，做這麼大的專案，因為中間遇到好多困難，卡住好幾次。<br><br>
        花了好多時間試錯，踩過無數個坑，遇到好多個問題，每次覺得無解，在放棄邊緣時，只能自我喊話"加油RRR!!!!"
        然後繼續debug......<br><br>
        雖然是大爆肝，也遇到臨時修改的問題，但看到成果，內心真的好港動，覺得好值得，所以絕對不能輕言放棄!<br><br>
        真的很有成就感，對自己也更有信心了:)<br><br>
        最後要感謝傑森哥和秀秀給我很多建議，敢放手讓我嘗試，還有海綿幫我解決最後輸出的問題，大感謝!爆肝客語組一起熬夜加班，新的里程碑達成!感謝現場vj把我們的作品呈現的很美!`,
        title: '彭佳慧x羅文裕 - 你的名字像一首詩 / 2021 第 32 屆金曲獎頒獎典禮',
        src: [
          require('@/assets/mg/gma32-1.jpg'),
          require('@/assets/mg/gma32-2.jpg'),
          require('@/assets/mg/gma32-3.jpg'),
          require('@/assets/mg/gma32-4.jpg'),
          require('@/assets/mg/gma32-5.jpg'),
          require('@/assets/mg/gma32-6.jpg'),
          require('@/assets/mg/gma32-7.jpg'),
          require('@/assets/mg/gma32-8.mp4'),
          require('@/assets/mg/gma32-9.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
        美術統籌_ J-ART FILM<br>
        現場視覺執行_REALIVE<br>
        專案經理_ mina<br>
        舞台美術_ 小綠<br>
        舞台動畫_ 小綠`,
        title: '四分衛 - 起來 / 2021 台北最 high 新年城',
        src: [
          require('@/assets/mg/getup-4.jpg'),
          require('@/assets/mg/getup-5.jpg'),
          require('@/assets/mg/getup-6.jpg'),
          require('@/assets/mg/getup-1.mp4'),
          require('@/assets/mg/getup-2.mp4'),
          require('@/assets/mg/getup-3.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
        一年好快就過去惹，今年學會新技能ue4，能用在大舞台上好開心(〃'▽'〃)<br>
        辛苦現場的vj惹，台北好冷又下雨(跑去南部避冬的me感到非常欽佩!<br><br>

        這一年感謝每個同事的幫助，也謝謝老闆放手讓我嘗試用新的創作方式做專案<br>
        新的一年還要更努力，開發更多技能(๑•̀ᄇ•́)و ✧<br><br>

        美術統籌_ J-ART FILM<br>
        現場視覺執行_REALIVE<br>
        專案經理_ mina<br>
        舞台美術_ 小綠<br>
        舞台動畫_ 小綠`,
        title: '梁靜茹 - 慢冷 / 2021 台北最 high 新年城',
        src: [
          require('@/assets/mg/high-slow-cold-4.mp4'),
          require('@/assets/mg/high-slow-cold-1.jpg'),
          require('@/assets/mg/high-slow-cold-2.jpg'),
          require('@/assets/mg/high-slow-cold-3.jpg'),
          require('@/assets/mg/high-slow-cold-8.mp4'),
          require('@/assets/mg/high-slow-cold-5.jpg'),
          require('@/assets/mg/high-slow-cold-6.jpg'),
          require('@/assets/mg/high-slow-cold-7.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
        紀錄WFH期間和同事一起製作的歌曲動畫<br><br>

        美術統籌_ Jason Lin<br>
        專案經理_ mina<br>
        舞台美術_ xinni、小綠<br>
        舞台動畫_ xinni、小綠`,
        title: '蔡徐坤 - Pull Up / 2021 蔡徐坤「迷」演唱會',
        src: [
          require('@/assets/mg/pullup-1.jpg'),
          require('@/assets/mg/pullup-2.jpg'),
          require('@/assets/mg/pullup-3.jpg'),
          require('@/assets/mg/pullup-4.jpg'),
          require('@/assets/mg/pullup-5.jpg'),
          require('@/assets/mg/pullup-6.jpg'),
          require('@/assets/mg/pullup-7.mp4'),
          require('@/assets/mg/pullup-8.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
        延了這麼多次終於順利演出惹!記得大半夜和 @ianakaberlin 拍手的影片，試了各種手的動作，拍到手快抽筋，終於讓這雙手登上小巨蛋的舞台惹!!! #放閃最極致ㄉ方式/////<br><br>
        感謝導演和boss給我實驗的機會，覺得超級好玩><感謝同事大大們的支援，受我一拜！<br><br>
        動畫團隊_ J-ART FILM<br>
        現場視訊執行_ REALIVE<br>
        視覺設計_傑森哥<br>
        專案經理_ Ｍina<br>
        動畫設計_ 小綠`,
        title: '許富凱 - Y.M.C.A. / 2021 許富凱拾歌演唱會',
        src: [
          require('@/assets/mg/kai-1.mp4'),
          require('@/assets/mg/kai-2.mp4'),
          require('@/assets/mg/kai-3.mp4'),
          require('@/assets/mg/kai-4.mp4'),
          require('@/assets/mg/kai-5.jpg'),
          require('@/assets/mg/kai-6.jpg'),
          require('@/assets/mg/kai-7.jpg'),
          require('@/assets/mg/kai-8.jpg'),
          require('@/assets/mg/kai-9.jpg'),
          require('@/assets/mg/kai-10.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          第一次用UE做舞台視覺動畫，遇到很多困難，但很有成就感，感謝同事、辦公室媽媽的貼心和鼓勵，為每個加班的夜晚增加溫暖 <3<br><br>

          @ianakaberlin<br>
          不管工作到凌晨還是早上，每天都來接我下班，xxoo.ヽ(^Д^*)/. 你最帥惹rrrrr<br><br>

          美術統籌_ J-ART FILM<br>
          現場視覺執行_REALIVE<br>
          專案經理_ mina<br>
          舞台美術_ 小綠<br>
          舞台動畫_ 小綠
        `,
        title: '閻奕格 You & I / 2021年 雙11 PChome 來了 BE YOUNIQUE 演唱會',
        src: [
          require('@/assets/mg/pchome2021-6.jpg'),
          require('@/assets/mg/pchome2021-1.mp4'),
          require('@/assets/mg/pchome2021-2.mp4'),
          require('@/assets/mg/pchome2021-3.mp4'),
          require('@/assets/mg/pchome2021-4.mp4'),
          require('@/assets/mg/pchome2021-5.mp4'),
          require('@/assets/mg/pchome2021-7.jpg'),
          require('@/assets/mg/pchome2021-8.jpg'),
          require('@/assets/mg/pchome2021-9.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          小宇 - 所謂的愛 / 2019年 雙11 PChome來了!演唱會<br><br>
          指導/主辦/監製：PChome網路家庭/PChome 24h購物<br>
          製作單位：就是現場JustLive<br>
          導演：小茜<br>
          現場執行統籌：REALIVE<br>
          動畫統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '小宇 - 所謂的愛 / 2019年 雙11 PChome來了!演唱會',
        src: [
          require('@/assets/mg/pchome-yu-4.mp4'),
          // require('@/assets/mg/pchome-yu-2.mp4'),
          // require('@/assets/mg/pchome-yu-3.mp4'),
          // require('@/assets/mg/pchome-yu-5.jpg'),
          // require('@/assets/mg/pchome-yu-6.jpg'),
          // require('@/assets/mg/pchome-yu-7.jpg'),
          require('@/assets/mg/pchome-yu-9.jpg'),
          require('@/assets/mg/pchome-yu-8.jpg'),
          require('@/assets/mg/pchome-yu-1.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          VERIVERY - Tag Tag Tag / 2019年 雙11 PChome來了!演唱會<br><br>
          指導/主辦/監製：PChome網路家庭/PChome 24h購物<br>
          製作單位：就是現場JustLive<br>
          導演：小茜<br>
          現場執行統籌：REALIVE<br>
          動畫統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: 'VERIVERY - Tag Tag Tag / 2019年 雙11 PChome來了!演唱會',
        src: [
          require('@/assets/mg/pchome-tag-3.mp4'),
          require('@/assets/mg/pchome-tag-1.mp4'),
          require('@/assets/mg/pchome-tag-2.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          徐佳瑩 - 失落沙洲 / 2019年 雙11 PChome來了!演唱會<br><br>
          指導/主辦/監製：PChome網路家庭/PChome 24h購物<br>
          製作單位：就是現場JustLive<br>
          導演：小茜<br>
          現場執行統籌：REALIVE<br>
          動畫統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '徐佳瑩 - 失落沙洲 / 2019年 雙11 PChome來了!演唱會',
        src: [
          require('@/assets/mg/pchome-hsu-2.mp4'),
          require('@/assets/mg/pchome-hsu-1.mp4'),
          require('@/assets/mg/pchome-hsu-3.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          小青龍 李若天 邱治諧 - Free Time / 上海梅奔香蕉娛樂家族演唱會《我們的演唱會》<br><br>
          Client：源活娛樂Young Hope<br>
          Producer：EVAN WU<br>
          Director：R.O. LIN<br>
          Executive Producer：JIA PEI<br>
          Led system：仁添<br>
          Led supervisor：ANDY <br>
          動畫統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '小青龍 李若天 邱治諧 - Free Time / 上海梅奔香蕉娛樂家族演唱會《我們的演唱會》',
        src: [
          require('@/assets/mg/freetime-1.mp4'),
          require('@/assets/mg/freetime-2.mp4'),
          require('@/assets/mg/freetime-3.jpg'),
          // require('@/assets/mg/freetime-3.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          王喆 - 覓 / 上海梅奔香蕉娛樂家族演唱會《我們的演唱會》<br><br>
          Client：源活娛樂Young Hope<br>
          Producer：EVAN WU<br>
          Director：R.O. LIN<br>
          Executive Producer：JIA PEI<br>
          Led system：仁添<br>
          Led supervisor：ANDY <br>
          動畫統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '王喆 - 覓 / 上海梅奔香蕉娛樂家族演唱會《我們的演唱會》',
        src: [
          // require('@/assets/mg/wang-1.mp4'),
          require('@/assets/mg/wang-3.mp4'),
          require('@/assets/mg/wang-2.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          小青龍 - 新光巷 / 上海梅奔香蕉娛樂家族演唱會《我們的演唱會》<br><br>
          Client：源活娛樂Young Hope<br>
          Producer：EVAN WU<br>
          Director：R.O. LIN<br>
          Executive Producer：JIA PEI<br>
          Led system：仁添<br>
          Led supervisor：ANDY <br>
          動畫統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '小青龍 - 新光巷 / 上海梅奔香蕉娛樂家族演唱會《我們的演唱會》',
        src: [
          require('@/assets/mg/wang-4.jpg'),
          require('@/assets/mg/wang-5.jpg'),
          require('@/assets/mg/wang-6.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          泰勒絲 Taylor Swift 出場 / Taylor swift china fan meeting「泰勒絲粉絲見面會in廣州」<br><br>
          Promoter：Universal music/TME/酷我音樂/酷狗音樂/QQ音樂<br>
          導演組：秋觀/鐵雄<br>
          視覺總監：Jason Ling<br>
          動畫統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '泰勒絲 Taylor Swift 出場 / Taylor swift china fan meeting「泰勒絲粉絲見面會in廣州」',
        src: [
          require('@/assets/mg/taylor-Swift-2.jpg'),
          require('@/assets/mg/taylor-Swift-3.jpg'),
          require('@/assets/mg/taylor-Swift-4.jpg'),
          require('@/assets/mg/taylor-Swift-5.jpeg'),
          require('@/assets/mg/taylor-Swift-1.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          楊丞琳 - 刪拾 / 第 15 屆 KKBOX 風雲榜頒獎典禮<br><br>
          主辦單位：KKBOX<br>
          製作單位：KKLIVE<br>
          導演：秋觀<br>
          視訊統籌：J-ART FILM/無觀設計<br>
          現場視覺執行：REALIVE/WUMY/MIN-CHING<br>
          動畫設計：小綠
        `,
        title: '楊丞琳 - 刪拾 / 第 15 屆 KKBOX 風雲榜頒獎典禮',
        src: [
          require('@/assets/mg/yang-4.jpg'),
          require('@/assets/mg/yang-1.mp4'),
          // require('@/assets/mg/yang-7.mp4'),
          require('@/assets/mg/yang-3.jpg'),
          require('@/assets/mg/yang-5.jpg'),
          require('@/assets/mg/yang-6.jpg'),
          require('@/assets/mg/yang-2.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          楊丞琳 - 年輪說 / 第 15 屆 KKBOX 風雲榜頒獎典禮<br><br>
          主辦單位：KKBOX<br>
          製作單位：KKLIVE<br>
          導演：秋觀<br>
          視訊統籌：J-ART FILM/無觀設計<br>
          現場視覺執行：REALIVE/WUMY/MIN-CHING<br>
          動畫設計：小綠
        `,
        title: '楊丞琳 - 年輪說 / 第 15 屆 KKBOX 風雲榜頒獎典禮',
        src: [
          require('@/assets/mg/yang-kkbox-4.jpg'),
          require('@/assets/mg/yang-kkbox-1.mp4'),
          require('@/assets/mg/yang-kkbox-2.jpg'),
          require('@/assets/mg/yang-kkbox-3.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          閻奕格 - please don\'t cry / 2020年 PChome狂禮88 小巨蛋演唱會<br><br>
          主辦單位：PChome / PChome 24h購物<br>
          承辦單位：PrN 皮爾森創意<br>
          製作單位：YoungHope 源活娛樂<br>
          總導演：佩禎<br>
          節目設計：莊佩禎/彭佳玲/吳政育/郭嘉霖<br>
          硬體統籌：郭嘉霖<br>
          工程統籌：楊明佃<br>
          視覺統籌：凌傑森Jason Ling<br>
          動畫設計：小綠
        `,
        title: '閻奕格 - please don\'t cry / 2020年 PChome狂禮88 小巨蛋演唱會',
        src: [
          require('@/assets/mg/please-dont-cry-7.jpg'),
          require('@/assets/mg/please-dont-cry-8.mp4'),
          require('@/assets/mg/please-dont-cry-1.mp4'),
          require('@/assets/mg/please-dont-cry-4.mp4'),
          require('@/assets/mg/please-dont-cry-5.mp4'),
          require('@/assets/mg/please-dont-cry-9.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          八三夭 - 搖勒搖勒 / 2020年 PChome狂禮88 小巨蛋演唱會<br><br>
          主辦單位：PChome / PChome 24h購物<br>
          承辦單位：PrN 皮爾森創意<br>
          製作單位：YoungHope 源活娛樂<br>
          總導演：佩禎<br>
          節目設計：莊佩禎/彭佳玲/吳政育/郭嘉霖<br>
          硬體統籌：郭嘉霖<br>
          工程統籌：楊明佃<br>
          視覺統籌：凌傑森Jason Ling<br>
          動畫設計：小綠
        `,
        title: '八三夭 - 搖勒搖勒 / 2020年 PChome狂禮88 小巨蛋演唱會',
        src: [
          require('@/assets/mg/pchome-831-7.jpg'),
          // require('@/assets/mg/pchome-831-8.jpg'),
          require('@/assets/mg/pchome-831-9.jpg'),
          require('@/assets/mg/pchome-831-10.jpg'),
          require('@/assets/mg/pchome-831-1.mp4'),
          // require('@/assets/mg/pchome-831-2.mp4'),
          // require('@/assets/mg/pchome-831-3.mp4'),
          // require('@/assets/mg/pchome-831-4.mp4'),
          // require('@/assets/mg/pchome-831-5.mp4'),
          // require('@/assets/mg/pchome-831-6.mp4'),
          // require('@/assets/mg/pchome-831-11.jpg'),
          // require('@/assets/mg/pchome-831-12.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          ØZI - FREE FALL | ØZI - B.O. / PChome【AMAZING FUTURE演唱會】<br><br>
          視覺統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: 'ØZI - FREE FALL | ØZI - B.O. / PChome【AMAZING FUTURE演唱會】',
        src: [
          require('@/assets/mg/ozi-3.jpg'),
          require('@/assets/mg/ozi-6.mp4'),
          require('@/assets/mg/ozi-7.mp4'),
          require('@/assets/mg/ozi-5.mp4'),
          require('@/assets/mg/ozi-1.mp4'),
          // require('@/assets/mg/ozi-2.mp4'),
          // require('@/assets/mg/ozi-4.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          伍佰 - 皇后的高跟鞋 / 臺北最 HIGH 新年城<br><br>
          視覺統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '伍佰 - 皇后的高跟鞋 / 臺北最 HIGH 新年城',
        src: [
          require('@/assets/mg/500-7.mp4'),
          require('@/assets/mg/500-8.jpg'),
          require('@/assets/mg/500-9.jpg'),
          require('@/assets/mg/500-1.mp4'),
          require('@/assets/mg/500-2.mp4'),
          require('@/assets/mg/500-3.mp4'),
          require('@/assets/mg/500-4.mp4'),
          require('@/assets/mg/500-5.mp4'),
          require('@/assets/mg/500-6.mp4'),
          require('@/assets/mg/500-10.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          周興哲 - 我很快樂 / 臺北最 HIGH 新年城<br><br>
          視覺統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '周興哲 - 我很快樂 / 臺北最 HIGH 新年城',
        src: [
          require('@/assets/mg/eric-chou-1.jpg'),
          require('@/assets/mg/eric-chou-4.mp4'),
          require('@/assets/mg/eric-chou-2.jpg'),
          require('@/assets/mg/eric-chou-3.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          盧廣仲 - 慢靈魂 / 盧廣仲 2020 春季巡迴<br><br>
          視覺統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '盧廣仲 - 慢靈魂 / 盧廣仲 2020 春季巡迴',
        src: [
          require('@/assets/mg/crowd-lu-11.jpg'),
          require('@/assets/mg/crowd-lu-1.mp4'),
          require('@/assets/mg/crowd-lu-2.mp4'),
          // require('@/assets/mg/crowd-lu-3.mp4'),
          // require('@/assets/mg/crowd-lu-4.mp4'),
          // require('@/assets/mg/crowd-lu-5.mp4'),
          require('@/assets/mg/crowd-lu-6.mp4'),
          require('@/assets/mg/crowd-lu-7.jpg'),
          require('@/assets/mg/crowd-lu-8.jpg'),
          require('@/assets/mg/crowd-lu-9.jpg'),
          // require('@/assets/mg/crowd-lu-10.jpg'),
        ],
        detailShow: false,
      },
      // {
      //   route: itemRoute,
      //   description: `
      //     全明星運動會主視覺動畫<br><br>
      //     動畫設計：小綠
      //   `,
      //   title: '全明星運動會主視覺動畫',
      //   src: [
      //     require('@/assets/mg/sport-4.jpg'),
      //     require('@/assets/mg/sport-2.mp4'),
      //     // require('@/assets/mg/sport-3.mp4'),
      //     // require('@/assets/mg/sport-1.mp4'),
      //     // require('@/assets/mg/sport-5.jpg'),
      //     require('@/assets/mg/sport-6.jpg'),
      //   ],
      //   detailShow: false,
      // },
      {
        route: itemRoute,
        description: `
          壞特 ?te - CAZZO / 台南好 Young 耶誕跨年城<br><br>
          動畫設計：小綠<br><br>
          歌名是義大利文的問ㄍ候ㄢ語ˋ，故事是圍繞女孩酒醒後，懊惱昨晚的一夜情，不停說著It's ok.<br>
          想用各種素材拼接在女孩身上，表現宿醉斷片的狀態，昨晚發生的一夜溫存，酒精與煙，好像還停留在皮膚的觸感記憶中<br>
          雖然一開始顧慮電視可能沒辦法轉播，但還是謝謝boss讓我嘗試用新的方式做歌曲動畫
        `,
        title: '壞特 ?te - CAZZO / 台南好 Young 耶誕跨年城',
        src: [
          require('@/assets/mg/te-10.jpg'),
          require('@/assets/mg/te-2.mp4'),
          require('@/assets/mg/te-4.mp4'),
          require('@/assets/mg/te-5.mp4'),
          require('@/assets/mg/te-6.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          壞特 ?te - babycakes / 台南好 Young 耶誕跨年城<br><br>
          動畫設計：小綠<br><br>
          我把整個公司都塞進這首歌惹XD<br>
          偷偷藏了彩蛋，大家可以找找看!
        `,
        title: '壞特 ?te - babycakes / 台南好 Young 耶誕跨年城',
        src: [
          require('@/assets/mg/te-1.mp4'),
          require('@/assets/mg/te-3.mp4'),
          require('@/assets/mg/te-7.jpg'),
          require('@/assets/mg/te-8.jpg'),
          require('@/assets/mg/te-9.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          陳立農 - 萬分之一 / 陳立農生日會<br><br>
          動畫設計：小綠<br><br>
          去年做了好多陳立農的歌曲，都是快歌，對點對得很有成就感。<br>
          想到第一次做快歌時很崩潰，做一首歌重複播放同一段音樂好幾遍，對點對到睡覺的時候也夢到自己在調key，真是 對。點。噩。夢<br>
          以此紀念每個與key共眠的夜晚～
        `,
        title: '陳立農 - 萬分之一 / 陳立農生日會',
        src: [
          require('@/assets/mg/linong-4.jpg'),
          require('@/assets/mg/linong-3.mp4'),
          require('@/assets/mg/linong-1.mp4'),
          require('@/assets/mg/linong-2.mp4'),
          require('@/assets/mg/linong-9.mp4'),
          require('@/assets/mg/linong-5.jpg'),
          require('@/assets/mg/linong-6.jpg'),
          require('@/assets/mg/linong-7.jpg'),
          require('@/assets/mg/linong-8.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          用餐中、用餐後視覺動畫 / 2020 某知名外商銀行奇幻饗宴<br><br>
          動畫設計：小綠<br><br>
          快要年夜飯了，來回顧一下去年的這個專案，這是在賓客用餐中，用投影的方式在桌面上呈現動畫的專案，讓賓客在用餐中也能擁有極致的視覺饗宴。<br>
          #我負責裡面最綠的動畫 同事做的動畫都好可愛，眼睛快要懷孕惹，同事的肝也要爆掉惹(我們領的不是薪水是醫療補助<br>
          #投影可以做這樣的表現真的太有趣惹 貧窮真是限制了我的想像，但也同時對動畫能呈現的視覺效果感到很驚喜，那時候boss還買了投影機，模擬投影在桌上的效果，整個公司的動畫師擠在小小辦公室，嘗試體會好野人的用餐環境(這算是一件幕後趣事嗎
        `,
        title: '用餐中、用餐後視覺動畫 / 2020 某知名外商銀行奇幻饗宴',
        src: [
          require('@/assets/mg/food-1.jpg'),
          require('@/assets/mg/food-9.mp4'),
          require('@/assets/mg/food-10.mp4'),
          require('@/assets/mg/food-2.jpg'),
          require('@/assets/mg/food-3.jpg'),
          require('@/assets/mg/food-4.jpg'),
          require('@/assets/mg/food-5.jpg'),
          require('@/assets/mg/food-6.jpg'),
          require('@/assets/mg/food-7.jpg'),
          require('@/assets/mg/food-8.mp4'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          小賴 - 乾我什麼事 / 2021 KKBOX 催下去音樂節 Turn Up Fest<br><br>
          動畫設計：小綠<br><br>
          在美術設定時覺得這首歌可以很嗆辣，也可以代表很多族群，歌詞很有態度，想用顏色鮮明，圖案強烈的風格做這首歌。<br>
          主歌前都是代表網路文化的圖案，副歌後是大解放，有各種部位的肌肉和內褲，還有逐漸升空的彩虹熱狗、彩虹香蕉，想要結合藝人的特質和歌詞意境，呈現出一種同志版美式漫畫的風格<br>
          小賴現場表演好棒! 就是我心中想要的畫面
        `,
        title: '小賴 - 乾我什麼事 / 2021 KKBOX 催下去音樂節 Turn Up Fest',
        src: [
          require('@/assets/mg/line-1.mp4'),
          require('@/assets/mg/line-2.mp4'),
          require('@/assets/mg/line-3.mp4'),
          require('@/assets/mg/line-4.mp4'),
          require('@/assets/mg/line-5.mp4'),
          require('@/assets/mg/line-6.mp4'),
          require('@/assets/mg/line-7.jpg'),
        ],
        detailShow: false,
      },
      {
        route: itemRoute,
        description: `
          八三夭 - 想見你想見你想見你 / 2021 KKBOX 催下去音樂節 Turn Up Fest<br><br>
          視覺統籌：J-ART FILM<br>
          動畫設計：小綠
        `,
        title: '八三夭 - 想見你想見你想見你 / 2021 KKBOX 催下去音樂節 Turn Up Fest',
        src: [
          require('@/assets/mg/831-1.jpg'),
          require('@/assets/mg/831-2.jpg'),
        ],
        detailShow: false,
      },
    ],
  },
  mutations: {
    SET_STATE: (state, data) => {
      state[data.key] = data.val
    },
  },
}