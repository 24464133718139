<template>
  <div>
    <Header />
    <div style="min-height: calc(100vh - 100px)">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  components: {
    Header,
    Footer,
  },
};
</script>