const itemRoute = '/web-work'

export default {
  namespaced: true,
  state: {
    webItems: [
      // {
      //   route: itemRoute,
      //   description: '會員登入、註冊、購物車、物流、金流與訂單管理⋯等。',
      //   title: '電子商務網站（服飾）',
      //   src: [
      //     require('@/assets/web/03.jpg'),
      //     require('@/assets/web/ec-1.jpg'),
      //     require('@/assets/web/ec-2.jpg'),
      //     require('@/assets/web/ec-3.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '一般流量導入網站，可在後台管理系統管理文章、查看瀏覽次數和報表，可多人操作。',
      //   title: '部落格網站 B',
      //   src: [
      //     require('@/assets/web/02.jpg'),
      //     require('@/assets/web/ec-blog-1.jpg'),
      //     require('@/assets/web/ec-blog-2.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '一般餐廳品牌網站。',
      //   title: '炭樂燒肉居酒屋・丼飯',
      //   src: [
      //     require('@/assets/web/food-1.png'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '一般餐廳品牌網站，會員登入、註冊、線上訂購、訂單查詢和匯款通知。',
      //   title: '尚品雞',
      //   src: [
      //     require('@/assets/web/food-2.png'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '仿造 Airbnb 體驗的 UI 設定和功能等。',
      //   title: 'Airbnb 體驗之風格網站',
      //   src: [
      //     require('@/assets/web/airbnb-1.jpg'),
      //     require('@/assets/web/airbnb-2.png'),
      //     require('@/assets/web/airbnb-3.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '基礎增刪改查，控制前台運作與資料展示。',
      //   title: '一般後台管理網站 A（後台管理系統）',
      //   src: [
      //     require('@/assets/web/01.jpg'),
      //     require('@/assets/web/b-backstage-1.jpg'),
      //     require('@/assets/web/b-backstage-2.jpg'),
      //     require('@/assets/web/b-backstage-3.jpg'),
      //     require('@/assets/web/b-backstage-4.jpg'),
      //     require('@/assets/web/b-backstage-5.jpg'),
      //     require('@/assets/web/b-backstage-6.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '基礎增刪改查，控制前台運作與資料展示。',
      //   title: '一般後台管理網站 B（後台管理系統）',
      //   src: [
      //     require('@/assets/web/04.jpg'),
      //     require('@/assets/web/c-backstage-1.jpg'),
      //     require('@/assets/web/c-backstage-2.jpg'),
      //     require('@/assets/web/c-backstage-3.jpg'),
      //   ],
      //   detailShow: false,
      // },
      // {
      //   route: itemRoute,
      //   description: '你能夠在旅行過程中減少分攤費用的窘境，誰先代墊、誰該付給誰多少錢，通通一目瞭然。',
      //   title: '旅遊 AA 小幫手（Line Bot 聊天機器人）',
      //   src: [
      //     require('@/assets/web/aa-1.jpeg'),
      //     require('@/assets/web/aa-2.jpeg'),
      //     require('@/assets/web/aa-3.jpeg'),
      //     require('@/assets/web/aa-4.jpeg'),
      //   ],
      //   detailShow: false,
      // },
    ],
  },
  mutations: {
    SET_STATE: (state, data) => {
      state[data.key] = data.val
    },
  },
}