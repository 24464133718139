import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'
import configCommon from '@/utils/configCommon'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/index'),
      },
      {
        path: '/mg-work/:id?',
        component: () => import('@/views/mg-work'),
        meta: { 
          title: 'MG 動畫作品'
        }
      },
      {
        path: '/concert-work/:id?',
        component: () => import('@/views/concert-work'),
        meta: { 
          title: '演唱會作品'
        }
      },
      {
        path: '/contact',
        component: () => import('@/views/contact'),
        meta: { 
          title: '聯絡我們'
        }
      },
      {
        path: '*',
        component: () => import('@/views/error'),
        meta: { 
          title: 'Error' 
        }
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { 
      x: 0, 
      y: 0,
      behavior: 'smooth' 
    }
  },
})

router.beforeEach((to, from, next) => {
  document.title = `${configCommon.webTitle}${to.meta.title ? ` - ${to.meta.title}` : ''}`
  next()
})

export default router
