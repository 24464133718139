<template>
  <div
    :class="[titleWithWorkCard ? 'title-work-card' : '']"
    class="text-sxl text-center title"
  >
    {{ text }}
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  props: {
    text: {
      require: true,
    },
    titleWithWorkCard: {
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 0 0 30px 0;
}
</style>